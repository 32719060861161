import construction from '../assets/icon/construction.png'
import extension from '../assets/icon/extension.png'
import renovation from '../assets/icon/renovation.png'
import surelevation from '../assets/icon/surelevation.png'
import appartment from '../assets/icon/appartment.png'
import house from '../assets/icon/house.png'
import commerce from '../assets/icon/commerce.png'
import batiment from '../assets/icon/batiment.png'
import recap from '../assets/icon/recap.png'
import recapitulatif from '../assets/icon/recapitulatif.png'
import ArrowBlanc from '../assets/icon/arrow_blanc.png'
import ArrowBleu from '../assets/icon/arrow_bleu.png'
import particulier from '../assets/icon/particulier.png'
import entreprise from '../assets/icon/entreprise.png'
import france from '../assets/icon/france.png'

import ic_dashboard from '../assets/icon/ic_dashboard.png'
import ic_activite from '../assets/icon/ic_activite.png'
import ic_chantier from '../assets/icon/ic_chantier.png'
import ic_general from '../assets/icon/ic_general.png'
import ic_information from '../assets/icon/ic_information.png'
import ic_preference from '../assets/icon/ic_preference.png'

import icLogoBleu from '../assets/icon/logo_kdec_fr_bleu.png'
import icLogoWhite from '../assets/icon/logo_kdec_fr_white.png'

import icSign from '../assets/icon/pen_sign.png'

import icShield from '../assets/icon/shield.png'
import icSecureCard from '../assets/icon/secure_card.png'


const Icons = {
    // icLogo: require('./'), //emplacement de l'icone
    icConstruction: construction,
    icExtension: extension,
    icRenovation: renovation,
    icSurelevation: surelevation,
    icAppartment: appartment,
    icHouse: house,
    icCommerce: commerce,
    icBatiment: batiment,
    icRecap: recap,
    icRecapitulatif: recapitulatif,
    icArrowBlanc: ArrowBlanc,
    icArrowBleu: ArrowBleu,
    icParticulier: particulier,
    icEntreprise: entreprise,
    icFrance: france,

    // professionnel
    icDashboard: ic_dashboard,
    icInformation: ic_information,
    icGeneral: ic_general,
    icActivite: ic_activite,
    icPreference: ic_preference,
    icChantier: ic_chantier,

    icLogoBleu: icLogoBleu,
    icLogoWhite: icLogoWhite,

    icSign: icSign,

    icShield: icShield,
    icSecureCard: icSecureCard,
}
export default Icons;
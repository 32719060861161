import { Helpers } from "./Helpers";

export const Request = {
    get: async (
        url,
        headers = {
            'Content-Type': 'application/json',
            ACCEPT: 'application/json'
        },
        options = null
    ) => {
        try {
            const jsonRes = await (await fetch(
                url,
                {
                    method: 'GET',
                    headers: headers,
                    ...options
                }
            )).json()
            return jsonRes;
        } catch (error) {
            // console.log('Fetch error')
        }
    },
    post: async (
        url,
        data,
        headers =
            // { "Content-type": "application/json;charset=UTF-8" },
            {
                'Content-Type': 'application/json',
                ACCEPT: 'application/json'
            },
        options = null
    ) => {
        try {
            const jsonRes = await (await fetch(
                url,
                {
                    // mode: 'cors',
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(data),
                    ...options
                }
            )).json()
            return jsonRes;
        } catch (error) {
            // console.log('Fetch error')
        }
    },
    getWithAuthorization: async (
        url,
        options = null
    ) => {
        try {
            let token = await Helpers.getToken().token
            const headersAuthorization = {
                'Content-Type': "application/json;charset=UTF-8",
                'Access-Control-Allow-Origin': '*',
                ACCEPT: 'application/json',
                Authorization: 'Bearer '.concat(token)
            }
            // console.log(headersAuthorization)
            const jsonRes = await (await fetch(
                url,
                {
                    method: 'GET',
                    headers: headersAuthorization,
                    ...options
                }
            )).json()
            return jsonRes;
        } catch (error) {
            // console.log('Fetch error')
            let token = await Helpers.getToken()
            if(token==null){
                window.location.href = "/login";
            }
        }
    },
    postWithAuthorization: async (
        url,
        data,
        options = null
    ) => {
        try {
            let token = await Helpers.getToken().token
            const headersAuthorization = {
                'Content-Type': "application/json;charset=UTF-8",
                'Access-Control-Allow-Origin': '*',
                ACCEPT: 'application/json',
                Authorization: 'Bearer '.concat(token)
            }
            const jsonRes = await (await fetch(
                url, {
                method: 'POST',
                headers: headersAuthorization,
                body: JSON.stringify(data),
                ...options
            }
            )).json()
            return jsonRes
        } catch (error) {
            // console.log('Fetch error')
            // console.log(JSON.stringify(error))
        }
    },
    patchWithAuthorization: async (
        url,
        data,
        options = null
    ) => {
        try {
            let token = await Helpers.getToken().token
            const headersAuthorization = {
                'Content-Type': "application/json;charset=UTF-8",
                'Access-Control-Allow-Origin': '*',
                ACCEPT: 'application/json',
                Authorization: 'Bearer '.concat(token)
            }
            const jsonRes = await (await fetch(
                url, {
                method: 'PATCH',
                headers: headersAuthorization,
                body: JSON.stringify(data),
                ...options
            }
            )).json()
            return jsonRes
        } catch (error) {
            // console.log('Fetch error')
            // console.log(JSON.stringify(error))
        }
    },
    upload: async (
        url,
        data
    ) => {
        try {
            let token = await Helpers.getToken().token
            const headersAuthorization = {
                // 'Content-Type': "application/json;charset=UTF-8",
                // 'Access-Control-Allow-Origin': '*',
                'Accept': 'multipart/form-data',
                Authorization: 'Bearer '.concat(token)
            }
            let jsonRes = await (await fetch(
                url,
                {
                    method: 'POST',
                    body: data,
                    headers: headersAuthorization,
                    // credentials: 'include',
                }
            )).json()
            return jsonRes;
        } catch (error) {
            // console.log('Fetch error')
            // console.log(error)
        }
    },
    actionUpload: async (
        url,
        headers = {
            'Content-Type': 'application/json',
            ACCEPT: 'application/json'
        },
        options = null
    ) => {
        try {
            const jsonRes = await (await fetch(
                url,
                {
                    method: 'GET',
                    headers: headers,
                    ...options
                }
            )).json()
            const result = {
                "name": "xxx.png",
                "status": "done",
                "url": "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
                "thumbUrl": "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
            }
            return result;
        } catch (error) {
            // console.log('Fetch error')
        }
    },

    // adobe sign
    postTypeGrant: async (
        url,
        data,
        headers =
            {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            }
    ) => {
        try {
            const jsonRes = await (await fetch(
                url,
                {
                    method: 'POST',
                    headers: headers,
                    body: data.toString()
                }
            )).json()
            return jsonRes;
        } catch (error) {
            // console.log('Fetch error')
        }
    },
    getWithBearer: async (
        url,
        token
    ) => {
        try {
            const headersAuthorization = {
                'Content-Type': 'application/json',
                ACCEPT: 'application/json',
                Authorization: 'Bearer '.concat(token)
            }
            let jsonRes = await (await fetch(
                url,
                {
                    method: 'GET',
                    headers: headersAuthorization
                }
            )).json()
            return jsonRes;
        } catch (error) {
            // console.log('Fetch error')
            // console.log(error)
        }
    },
    postWithBearer: async (
        url,
        data,
        token
    ) => {
        try {
            const headersAuthorization = {
                'Content-Type': 'application/json',
                ACCEPT: 'application/json',
                Authorization: 'Bearer '.concat(token)
            }
            let jsonRes = await (await fetch(
                url,
                {
                    method: 'POST',
                    headers: headersAuthorization,
                    body: JSON.stringify(data)
                }
            )).json()
            return jsonRes;
        } catch (error) {
            // console.log('Fetch error')
            // console.log(error)
        }
    }
}

import React, { Component, useState, useEffect } from 'react';
import { Avatar } from 'antd'
import { Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import './Navbar.scss';
import { Helpers } from '../../config/Helpers';
import { useHistory } from 'react-router-dom';
import { Request } from '../../config/Request';
import { useDispatch, useSelector } from 'react-redux';
import { VERSION } from '../../config/Host';
function Navbar(props) {
  const navigate = useHistory()
  const dispatch = useDispatch()

  const [user, setUser] = useState(null)
  const dataOffer = useSelector(state => state.Offer.offer)

  useEffect(() => {
    console.clear()
    let user = Helpers.getUser()?.datauser
    setUser(user)
    if (user) {
      initDataCallOffer()
    }
  }, [])
  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  const toggleRightSidebar = () => {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }
  const _onDeconnexion = () => {
    sessionStorage.clear()
    localStorage.clear()
    window.location.href = "/";
  }
  const goToProfil = () => {
    let id = user.id
    navigate.push("/profil", { state: { id: id } })
  }
  const initDataCallOffer = async () => {
    let id = Helpers.getUser().datauser.id
    const url = Helpers.getUser().datauser.user_type === "admin" ? URL.getListOffreProfessionelbyAdmin : URL.getListOffreProfessionnel + id
    let resultat = Helpers.getUser().datauser.user_type === "admin" ? await Request.getWithAuthorization(url)
      : await Request.getWithAuthorization(url)
    if (resultat.success) {
      let offerValid = resultat.data.filter(item => item.status == "Disponible" && item.validation === "1")
      dispatch({ type: 'SET_DATA_OFFER', value: offerValid.length })
    }
  }
  const _onClickNotif = () => {
    navigate.push("/list-offre")
  }
  return (
    <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row" >
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between" style={{ backgroundColor: '#E9E9E9', boxShadow: ' 0 9px 1px -6px #80808052' }}>
        <a className="navbar-brand brand-logo-mini align-self-center d-lg-none" href="!#" onClick={evt => evt.preventDefault()}>
          <img className="my-logo" src={require("../../assets/icon/logo_kdec_fr_bleu.png")} alt="logo" />
        </a>
        <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
          <i className="mdi mdi-menu"></i>
        </button>
        {/* <ul className="navbar-nav navbar-nav-left header-links align-self-center">
            <li className="nav-item font-weight-semibold d-none  d-md-flex">Help : +050 2992 709</li>
            <li className="nav-item dropdown language-dropdown">
            <Dropdown>
                <Dropdown.Toggle className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                  <div className="d-inline-flex mr-0 mr-md-3">
                    <div className="flag-icon-holder">
                      <i className="flag-icon flag-icon-us"></i>
                    </div>
                  </div>
                  <span className="profile-text font-weight-medium d-none d-md-block">English</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="navbar-dropdown preview-list">
                  <Dropdown.Item className="dropdown-item  d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                    <div className="flag-icon-holder">
                      <i className="flag-icon flag-icon-us"></i>
                    </div>English
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                    <div className="flag-icon-holder">
                      <i className="flag-icon flag-icon-fr"></i>
                    </div>French
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                    <div className="flag-icon-holder">
                      <i className="flag-icon flag-icon-ae"></i>
                    </div>Arabic
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                    <div className="flag-icon-holder">
                      <i className="flag-icon flag-icon-ru"></i>
                    </div>Russian
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
          <form className="ml-auto search-form d-none d-md-block" action="#">
            <div className="form-group">
              <input type="search" className="form-control" placeholder="Search Here" />
            </div>
          </form> */}
        <ul className="navbar-nav navbar-nav-right" style={{ marginRight: '5%' }}>
          <li className="nav-item  nav-profile border-0 pl-4">
            {/* <i className="mdi mdi-bell" style={{ color: '#2677EF', fontSize: '15px' }}></i> */}

            <Dropdown>
              <Dropdown.Toggle className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                <i className="mdi mdi-bell" style={{ color: '#2677EF', fontSize: '15px' }}></i>
                {dataOffer !== 0 && <span className="count ">{dataOffer}</span>}
              </Dropdown.Toggle>
              <Dropdown.Menu className="navbar-dropdown preview-list">
                <Dropdown.Item className="dropdown-item py-3 d-flex align-items-center" href="!#" onClick={evt => evt.preventDefault()}>
                  <p className="mb-0 font-weight-medium float-left"><Trans>Vous avez</Trans> {dataOffer} <Trans>appel d'offre disponible</Trans> </p>
                  <span className="badge badge-pill badge-primary float-right" onClick={() => _onClickNotif()}>Voir</span>
                </Dropdown.Item>
                {/* <div className="dropdown-divider"></div>
                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt => evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <i className="mdi mdi-alert m-auto text-primary"></i>
                  </div>
                  <div className="preview-item-content py-2">
                    <h6 className="preview-subject font-weight-normal text-dark mb-1"><Trans>Application Error</Trans></h6>
                    <p className="font-weight-light small-text mb-0"> <Trans>Just now</Trans> </p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt => evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <i className="mdi mdi-settings m-auto text-primary"></i>
                  </div>
                  <div className="preview-item-content py-2">
                    <h6 className="preview-subject font-weight-normal text-dark mb-1"><Trans>Settings</Trans></h6>
                    <p className="font-weight-light small-text mb-0"> <Trans>Private message</Trans> </p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt => evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <i className="mdi mdi-airballoon m-auto text-primary"></i>
                  </div>
                  <div className="preview-item-content py-2">
                    <h6 className="preview-subject font-weight-normal text-dark mb-1"><Trans>New user registration</Trans></h6>
                    <p className="font-weight-light small-text mb-0"> 2 <Trans>days ago</Trans> </p>
                  </div>
                </Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li className="nav-item  nav-profile border-0">
            <i className="mdi mdi-email" style={{ color: '#2677EF', fontSize: '15px' }}></i>

            {/* <Dropdown>
              <Dropdown.Toggle className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                <i className="mdi mdi-email" style={{ color: '#2677EF', fontSize: '15px' }}></i>
                <span className="count">7</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="navbar-dropdown preview-list">
                <Dropdown.Item className="dropdown-item  d-flex align-items-center" href="!#" onClick={evt => evt.preventDefault()}>
                  <p className="mb-0 font-weight-medium float-left"><Trans>You have</Trans> 7 <Trans>unread mails</Trans> </p>
                  <span className="badge badge-pill badge-primary">View all</span>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt => evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <img src={require("../../assets/images/faces/face10.jpg")} alt="profile" className="img-sm profile-pic" /> </div>
                  <div className="preview-item-content flex-grow py-2">
                    <p className="preview-subject ellipsis font-weight-medium text-dark"><Trans>Marian Garner</Trans> </p>
                    <p className="font-weight-light small-text"> <Trans>The meeting is cancelled</Trans> </p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt => evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <img src={require("../../assets/images/faces/face12.jpg")} alt="profile" className="img-sm profile-pic" /> </div>
                  <div className="preview-item-content flex-grow py-2">
                    <p className="preview-subject ellipsis font-weight-medium text-dark"><Trans>David Grey</Trans> </p>
                    <p className="font-weight-light small-text"> <Trans>The meeting is cancelled</Trans></p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt => evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <img src={require("../../assets/images/faces/face1.jpg")} alt="profile" className="img-sm profile-pic" /> </div>
                  <div className="preview-item-content flex-grow py-2">
                    <p className="preview-subject ellipsis font-weight-medium text-dark"><Trans>Travis Jenkins</Trans> </p>
                    <p className="font-weight-light small-text"> <Trans>The meeting is cancelled</Trans> </p>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
          </li>


          <li className="nav-item  nav-profile border-0">
            <Dropdown>
              <Dropdown.Toggle className="nav-link count-indicator bg-transparent">
                {/* <img className="img-xs rounded-circle" src={require("../../assets/images/faces/face8.jpg")} alt="Profile" />  +user.lastname.toString().toUpperCase() */}   
                <div className="d-flex" style={{ borderRadius: "20px", gap: "10px" }}>
                  <div className='typeUser'><span className='textTypeUser'>{user == null ? "" : user.firstname.toString().toUpperCase()+" "}</span></div>
                  <Avatar size={40}>{user == null ? "K" : (user.firstname == null ? "K" : user.firstname.toString().toUpperCase()[0])}</Avatar>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
                <div className='d-flex'>
                  <Dropdown.Item className="dropdown-item p-0 preview-item d-flex align-items-center border-bottom" href="!#" onClick={evt => evt.preventDefault()}>
                    <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                      <i className="mdi mdi-bookmark-plus-outline mr-0"></i>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item p-0 preview-item d-flex align-items-center border-bottom" onClick={goToProfil}>
                    <div className="py-3 px-4 d-flex align-items-center justify-content-center border-left border-right">
                      <i className="mdi mdi-account-outline mr-0"></i>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item p-0 preview-item d-flex align-items-center border-bottom" href="!#" onClick={evt => evt.preventDefault()}>
                    <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                      <i className="mdi mdi-alarm-check mr-0"></i>
                    </div>
                  </Dropdown.Item>
                </div>

                {/* <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0 mt-2" onClick={evt => evt.preventDefault()}>
                  <Trans>Manage Accounts</Trans>
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0" onClick={evt => evt.preventDefault()}>
                  <Trans>Change Password</Trans>
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0" onClick={evt => evt.preventDefault()}>
                  <Trans>Check Inbox</Trans>
                </Dropdown.Item> */}
                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0">
                  <Trans>kawadessin Version <strong style={{ marginLeft: 3 }}>{VERSION}</strong></Trans>
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0" onClick={() => _onDeconnexion()}>
                  <Trans>Deconnexion</Trans>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
}

export default Navbar;

export const API_HOST = `https://api.hsforms.com/submissions/v3/integration/submit/`
export const API_ADRESSE = `https://api-adresse.data.gouv.fr/search/?q=`
export const API_SIRET = `https://entreprise.data.gouv.fr/api/sirene/v1/siret/`
// export const API = `https://dev.kawadessin.fr/`

// //DEV
// export const API = `https://api.dev.kawadessin.fr/`
// export const FRONT = `https://dev.kawadessin.fr/`

// export const API = `http://127.0.0.1:8000/`

//PROD
export const API = `https://api.kawadessin.fr/`
export const FRONT = `https://permis.kawadessin.fr/`

// compte Dev client
export const default_token = "3AAABLblqZhD-ziXQS-REPhr_2cOku2Cv5AAu_M0tk30Zwws8E5FXSnOhUoD9rEbyAFGTOTWPoGA*"
export const client_id = "CBJCHBCAABAA3kF6JEupIjNtK_-8P4STWtxdzfuxAl8D"
export const client_secret  = "EA0_t_PlQDCcD3Fn_40s5XaOtA7GiGx-"
export const refresh_token  = "refresh_token"
export const VERSION  = "4.1.3"
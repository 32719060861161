export const Helpers = {
    setToken: (token) => {
        sessionStorage.setItem('token', JSON.stringify(token));
    },
    getToken: () => {
        const tokenDataString = sessionStorage.getItem('token');
        const tokenData = JSON.parse(tokenDataString);
        return tokenData
    },
    setTokenAdobeSign: (token) => {
        sessionStorage.setItem('tokenSign', JSON.stringify(token));
    },
    getTokenAdobeSign: () => {
        const tokenDataString = sessionStorage.getItem('tokenSign');
        const tokenData = JSON.parse(tokenDataString);
        return tokenData
    },
    setsupport: (support) => {
        sessionStorage.setItem('support', JSON.stringify(support));
    },
    getSupport: () => {
        const supportDataString = sessionStorage.getItem('support');
        const supportData = JSON.parse(supportDataString);
        return supportData
    },
    setProjet: (projet) => {
        sessionStorage.setItem('projet', JSON.stringify(projet));
    },
    getProjet: () => {
        const projetDataString = sessionStorage.getItem('projet');
        const projetData = JSON.parse(projetDataString);
        return projetData
    },
    setBien: (bien) => {
        sessionStorage.setItem('bien', JSON.stringify(bien));
    },
    getBien: () => {
        const bienDataString = sessionStorage.getItem('bien');
        const bienData = JSON.parse(bienDataString);
        return bienData
    },
    setVille: (ville) => {
        sessionStorage.setItem('ville', JSON.stringify(ville));
    },
    getVille: () => {
        const villeDataString = sessionStorage.getItem('ville');
        const villeData = JSON.parse(villeDataString);
        return villeData
    },
    setAdresse: (adresse) => {
        if (adresse === undefined) { adresse = null }
        sessionStorage.setItem('adresse', JSON.stringify(adresse));
    },
    getAdresse: () => {
        const adresseDataString = sessionStorage.getItem('adresse');
        const adresseData = JSON.parse(adresseDataString);
        return adresseData
    },
    setSurface: (surface) => {
        sessionStorage.setItem('surface', JSON.stringify(surface));
    },
    getSurface: () => {
        const surfaceDataString = sessionStorage.getItem('surface');
        const surfaceData = JSON.parse(surfaceDataString);
        return surfaceData
    },
    setSurfaceTravaux: (surfaceTravaux) => {
        sessionStorage.setItem('surfaceTravaux', JSON.stringify(surfaceTravaux));
    },
    getSurfaceTravaux: () => {
        const surfaceTravauxDataString = sessionStorage.getItem('surfaceTravaux');
        const surfaceTravauxData = JSON.parse(surfaceTravauxDataString);
        return surfaceTravauxData
    },
    setBudget: (budget) => {
        sessionStorage.setItem('budget', JSON.stringify(budget));
    },
    getBudget: () => {
        const budgetDataString = sessionStorage.getItem('budget');
        const budgetData = JSON.parse(budgetDataString);
        return budgetData
    },
    setTimes: (times) => {
        sessionStorage.setItem('times', JSON.stringify(times));
    },
    getTimes: () => {
        const timesDataString = sessionStorage.getItem('times');
        const timesData = JSON.parse(timesDataString);
        return timesData
    },
    setProprio: (proprio) => {
        sessionStorage.setItem('proprio', JSON.stringify(proprio));
    },
    getProprio: () => {
        const proprioDataString = sessionStorage.getItem('proprio');
        const proprioData = JSON.parse(proprioDataString);
        return proprioData
    },
    setDescription: (description) => {
        sessionStorage.setItem('description', JSON.stringify(description));
    },
    getDescription: () => {
        const descriptionDataString = sessionStorage.getItem('description');
        const descriptionData = JSON.parse(descriptionDataString);
        return descriptionData
    },
    setAccompagnement: (accompagnement) => {
        sessionStorage.setItem('accompagnement', JSON.stringify(accompagnement));
    },
    getAccompagnement: () => {
        const accompagnementDataString = sessionStorage.getItem('accompagnement');
        const accompagnementData = JSON.parse(accompagnementDataString);
        return accompagnementData
    },
    setBesoinAccompagnement: (besoinAccompagnement) => {
        sessionStorage.setItem('besoinAccompagnement', JSON.stringify(besoinAccompagnement));
    },
    getBesoinAccompagnement: () => {
        const besoinAccompagnementDataString = sessionStorage.getItem('besoinAccompagnement');
        const besoinAccompagnementData = JSON.parse(besoinAccompagnementDataString);
        return besoinAccompagnementData
    },
    setDelai: (delai) => {
        sessionStorage.setItem('delai', JSON.stringify(delai));
    },
    getDelai: () => {
        const delaiDataString = sessionStorage.getItem('delai');
        const delaiData = JSON.parse(delaiDataString);
        return delaiData
    },
    setCivility: (civilityClient) => {
        if (civilityClient === undefined) { civilityClient = null }
        sessionStorage.setItem('civilityClient', JSON.stringify(civilityClient));
    },
    getCivility: () => {
        const civilityClientDataString = sessionStorage.getItem('civilityClient');
        const civilityClientData = JSON.parse(civilityClientDataString);

        return civilityClientData
    },
    setNom: (nomClient) => {
        if (nomClient === undefined) { nomClient = null }
        sessionStorage.setItem('nomClient', JSON.stringify(nomClient));
    },
    getNom: () => {
        const nomClientDataString = sessionStorage.getItem('nomClient');
        const nomClientData = JSON.parse(nomClientDataString);

        return nomClientData
    },
    setPrenom: (prenomClient) => {
        if (prenomClient === undefined) { prenomClient = null }
        sessionStorage.setItem('prenomClient', JSON.stringify(prenomClient));
    },
    getPrenom: () => {
        const prenomClientDataString = sessionStorage.getItem('prenomClient');
        const prenomClientData = JSON.parse(prenomClientDataString);
        return prenomClientData
    },
    setEmail: (emailClient) => {
        if (emailClient === undefined) { emailClient = null }
        sessionStorage.setItem('emailClient', JSON.stringify(emailClient));
    },
    getEmail: () => {
        const emailClientDataString = sessionStorage.getItem('emailClient');
        const emailClientData = JSON.parse(emailClientDataString);
        return emailClientData
    },
    setPhone: (phoneClient) => {
        sessionStorage.setItem('phoneClient', JSON.stringify(phoneClient));
    },
    getPhone: () => {
        const phoneClientDataString = sessionStorage.getItem('phoneClient');
        const phoneClientData = JSON.parse(phoneClientDataString);
        return phoneClientData
    },
    setCountry: (countryClient) => {
        sessionStorage.setItem('countryClient', JSON.stringify(countryClient));
    },
    getCountry: () => {
        const countryClientDataString = sessionStorage.getItem('countryClient');
        const countryClientData = JSON.parse(countryClientDataString);
        return countryClientData
    },
    setValueBien: (value) => {
        sessionStorage.setItem('valueBien', JSON.stringify(value));
    },
    getValueBien: () => {
        const valueBienDataString = sessionStorage.getItem('valueBien');
        const valueBienData = JSON.parse(valueBienDataString);
        return valueBienData
    },
    setStreet: (value) => {
        sessionStorage.setItem('street', JSON.stringify(value));
    },
    getStreet: () => {
        const streetDataString = sessionStorage.getItem('street');
        const streetData = JSON.parse(streetDataString);
        return streetData
    },
    setCodePostale: (value) => {
        sessionStorage.setItem('codePostale', JSON.stringify(value));
    },
    getCodePostale: () => {
        const codePostaleDataString = sessionStorage.getItem('codePostale');
        const codePostaleData = JSON.parse(codePostaleDataString);
        return codePostaleData
    },
    setCityCode: (value) => {
        sessionStorage.setItem('cityCode', JSON.stringify(value));
    },
    getCityCode: () => {
        const cityCodeDataString = sessionStorage.getItem('cityCode');
        const cityCodeData = JSON.parse(cityCodeDataString);
        return cityCodeData
    },

    // Show adresse
    setShowAdresse: (value) => {
        sessionStorage.setItem('showAdresse', JSON.stringify(value));
    },
    getShowAdresse: () => {
        const showAdresseDataString = sessionStorage.getItem('showAdresse');
        const showAdresseData = JSON.parse(showAdresseDataString);
        return showAdresseData
    },
    // Data user
    setUser: (value) => {
        sessionStorage.setItem('userData', JSON.stringify(value));
    },
    getUser: () => {
        const userDataString = sessionStorage.getItem('userData');
        const userData = JSON.parse(userDataString);
        return userData
    },
    setTypeClient: (value) => {
        sessionStorage.setItem('typeClient', JSON.stringify(value));
    },
    getTypeClient: () => {
        const typeClientDataString = sessionStorage.getItem('typeClient');
        const typeClientData = JSON.parse(typeClientDataString);
        return typeClientData
    }
}
import React, { Component } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Trans } from 'react-i18next';

import './Sidebar.scss';
import { Helpers } from '../../config/Helpers';
import Icons from '../../config/Icons';
class Sidebar extends Component {
  // function Sidebar() {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      // state to open menu on subMenu clicked
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/entreprise', state: 'EntrepriseMenuOpen' },
      { path: '/gestion-user', state: 'GestionUserMenuOpen' },
      { path: '/gestion-produit', state: 'GestionProduitMenuOpen' },
      { path: '/devis', state: 'GestionDevisMenuOpen' },
      { path: '/form-elements', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' },
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    }));

  }
  _onCreateNewProject() {
    //sessionStorage.removeItem("token");
    window.location.href = "/";
  }
  _onDeconnexion() {
    sessionStorage.clear()
    localStorage.clear()
    window.location.href = "/";
  }

  _viewMenuPermission() {
    switch (Helpers.getUser()?.datauser.user_type) {
      case "professionnel":
        return <>
          <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-view-dashboard menu-icon" style={{ color: 'white' }}></i>
              <span className="menu-title"><Trans>Tableau de bord</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/entreprise') ? 'nav-item active ' : 'nav-item '} style={{ borderRadius: '10px' }}>
            <div className={this.state.EntrepriseMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('EntrepriseMenuOpen')} data-toggle="collapse" >
              <i className="mdi mdi-trello menu-icon" style={{ color: 'white' }}></i>
              <span className="menu-title"><Trans>Entreprise</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.EntrepriseMenuOpen} className="align-center arrondie-bas">
              <ul className="nav flex-column sub-menu ">
                <li className="nav-item">
                  <Link className={this.isPathActive('/entreprise/information-legale') ? 'nav-link active d-flex flex-row ' : 'nav-link d-flex flex-row '} to="/entreprise/information-legale">
                    <Trans><span className="submenu-title">Informations légales<br /> de l'agence</span></Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={this.isPathActive('/entreprise/information-generale') ? 'nav-link active d-flex flex-row  ' : 'nav-link d-flex flex-row '} to="/entreprise/information-generale">
                    <Trans><span className="submenu-title">Informations générales</span></Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={this.isPathActive('/entreprise/metier') ? 'nav-link active d-flex flex-row ' : 'nav-link d-flex flex-row '} to="/entreprise/metier">
                    <Trans><span className="submenu-title">Le métier</span></Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={this.isPathActive('/entreprise/reference') ? 'nav-link active d-flex flex-row ' : 'nav-link d-flex flex-row '} to="#">
                    <Trans><span className="submenu-title">Chantier de référence</span></Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={this.isPathActive('/entreprise/preference') ? 'nav-link active d-flex flex-row' : 'nav-link d-flex flex-row '} to="/entreprise/preference">
                    <Trans><span className="submenu-title">Préférences</span></Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={this.isPathActive('/entreprise/zone') ? 'nav-link active d-flex flex-row' : 'nav-link d-flex flex-row '} to="/entreprise/zone">
                    <Trans><span className="submenu-title">Zone intervention</span></Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          {Helpers.getUser().datauser.is_validate === "Validé" &&
            <>
              <li className={this.isPathActive('/list-offre') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/list-offre">
                  <i className="mdi mdi-view-list menu-icon" style={{ color: 'white' }}></i>
                  <span className="menu-title"><Trans>Appel d'offre</Trans></span>
                  <i className=""></i>
                </Link>
              </li>
            </>}
        </>
        break;
      case "dessinateur":
        return <>
          <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-view-dashboard menu-icon" style={{ color: 'white' }}></i>
              <span className="menu-title"><Trans>Tableau de bord</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/entreprise') ? 'nav-item active ' : 'nav-item '} style={{ borderRadius: '10px' }}>
            <div className={this.state.EntrepriseMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('EntrepriseMenuOpen')} data-toggle="collapse" >
              <i className="mdi mdi-trello menu-icon" style={{ color: 'white' }}></i>
              <span className="menu-title"><Trans>Entreprise</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.EntrepriseMenuOpen} className="align-center arrondie-bas">
              <ul className="nav flex-column sub-menu ">
                <li className="nav-item">
                  <Link className={this.isPathActive('/entreprise/information-legale') ? 'nav-link active d-flex flex-row ' : 'nav-link d-flex flex-row '} to="/entreprise/information-legale">
                    <Trans><span className="submenu-title">Informations légales<br /> de l'agence</span></Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={this.isPathActive('/entreprise/information-generale') ? 'nav-link active d-flex flex-row  ' : 'nav-link d-flex flex-row '} to="/entreprise/information-generale">
                    <Trans><span className="submenu-title">Informations générales</span></Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={this.isPathActive('/entreprise/metier') ? 'nav-link active d-flex flex-row ' : 'nav-link d-flex flex-row '} to="/entreprise/metier">
                    <Trans><span className="submenu-title">Le métier</span></Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={this.isPathActive('/entreprise/reference') ? 'nav-link active d-flex flex-row ' : 'nav-link d-flex flex-row '} to="#">
                    <Trans><span className="submenu-title">Chantier de référence</span></Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={this.isPathActive('/entreprise/preference') ? 'nav-link active d-flex flex-row' : 'nav-link d-flex flex-row '} to="/entreprise/preference">
                    <Trans><span className="submenu-title">Préférences</span></Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={this.isPathActive('/entreprise/zone') ? 'nav-link active d-flex flex-row' : 'nav-link d-flex flex-row '} to="/entreprise/zone">
                    <Trans><span className="submenu-title">Zone intervention</span></Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          {Helpers.getUser().datauser.is_validate === "Validé" &&
            <>
              <li className={this.isPathActive('/list-offre') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/list-offre">
                  <i className="mdi mdi-view-list menu-icon" style={{ color: 'white' }}></i>
                  <span className="menu-title"><Trans>Appel d'offre</Trans></span>
                  <i className=""></i>
                </Link>
              </li>
            </>}
        </>
        break;
      case "architecte":
        return <>
          <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-view-dashboard menu-icon" style={{ color: 'white' }}></i>
              <span className="menu-title"><Trans>Tableau de bord</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/entreprise') ? 'nav-item active ' : 'nav-item '} style={{ borderRadius: '10px' }}>
            <div className={this.state.EntrepriseMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('EntrepriseMenuOpen')} data-toggle="collapse" >
              <i className="mdi mdi-trello menu-icon" style={{ color: 'white' }}></i>
              <span className="menu-title"><Trans>Entreprise</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.EntrepriseMenuOpen} className="align-center arrondie-bas">
              <ul className="nav flex-column sub-menu ">
                <li className="nav-item">
                  <Link className={this.isPathActive('/entreprise/information-legale') ? 'nav-link active d-flex flex-row ' : 'nav-link d-flex flex-row '} to="/entreprise/information-legale">
                    <Trans><span className="submenu-title">Informations légales<br /> de l'agence</span></Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={this.isPathActive('/entreprise/information-generale') ? 'nav-link active d-flex flex-row  ' : 'nav-link d-flex flex-row '} to="/entreprise/information-generale">
                    <Trans><span className="submenu-title">Informations générales</span></Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={this.isPathActive('/entreprise/metier') ? 'nav-link active d-flex flex-row ' : 'nav-link d-flex flex-row '} to="/entreprise/metier">
                    <Trans><span className="submenu-title">Le métier</span></Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={this.isPathActive('/entreprise/reference') ? 'nav-link active d-flex flex-row ' : 'nav-link d-flex flex-row '} to="#">
                    <Trans><span className="submenu-title">Chantier de référence</span></Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={this.isPathActive('/entreprise/preference') ? 'nav-link active d-flex flex-row' : 'nav-link d-flex flex-row '} to="/entreprise/preference">
                    <Trans><span className="submenu-title">Préférences</span></Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={this.isPathActive('/entreprise/zone') ? 'nav-link active d-flex flex-row' : 'nav-link d-flex flex-row '} to="/entreprise/zone">
                    <Trans><span className="submenu-title">Zone intervention</span></Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          {Helpers.getUser().datauser.is_validate === "Validé" &&
            <>
              <li className={this.isPathActive('/list-offre') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/list-offre">
                  <i className="mdi mdi-view-list menu-icon" style={{ color: 'white' }}></i>
                  <span className="menu-title"><Trans>Appel d'offre</Trans></span>
                  <i className=""></i>
                </Link>
              </li>
              <li className={this.isPathActive('/gestion-produit') ? 'nav-item active ' : 'nav-item '} style={{ borderRadius: '10px' }}>
                <div className={this.state.GestionProduitMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('GestionProduitMenuOpen')} data-toggle="collapse" >
                  <i className="mdi mdi-view-stream menu-icon" style={{ color: 'white' }}></i>
                  <span className="menu-title"><Trans>Gestion Produit</Trans></span>
                  <i className="menu-arrow"></i>
                </div>
                <Collapse in={this.state.GestionProduitMenuOpen} className="align-center arrondie-bas">
                  <ul className="nav flex-column sub-menu ">
                    <li className="nav-item">
                      <Link className={this.isPathActive('/gestion-produit/list') ? 'nav-link active d-flex flex-row ' : 'nav-link d-flex flex-row '} to="/gestion-produit/list">
                        <Trans><span className="submenu-title">Liste</span></Trans>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className={this.isPathActive('/gestion-produit/ajout') ? 'nav-link active d-flex flex-row  ' : 'nav-link d-flex flex-row '} to="/gestion-produit/ajout">
                        <Trans><span className="submenu-title">Ajout</span></Trans>
                      </Link>
                    </li>
                  </ul>
                </Collapse>
              </li>
              <li className={this.isPathActive('/devis') ? 'nav-item active ' : 'nav-item '} style={{ borderRadius: '10px' }}>
                <div className={this.state.GestionDevisMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('GestionDevisMenuOpen')} data-toggle="collapse" >
                  <i className="mdi mdi-wallet menu-icon" style={{ color: 'white' }}></i>
                  <span className="menu-title"><Trans>Gestion Devis</Trans></span>
                  <i className="menu-arrow"></i>
                </div>
                <Collapse in={this.state.GestionDevisMenuOpen} className="align-center arrondie-bas">
                  <ul className="nav flex-column sub-menu ">
                    <li className="nav-item">
                      <Link className={this.isPathActive('/devis/list') ? 'nav-link active d-flex flex-row ' : 'nav-link d-flex flex-row '} to={{ pathname: `/devis/list/`, state: { id: Helpers.getUser() == null ? null : Helpers.getUser().datauser.id } }}>
                        <Trans><span className="submenu-title">Mes devis</span></Trans>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className={this.isPathActive('/devis/new') ? 'nav-link active d-flex flex-row  ' : 'nav-link d-flex flex-row '} to="/devis/new">
                        <Trans><span className="submenu-title">Création</span></Trans>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className={this.isPathActive('/devis/parametre') ? 'nav-link active d-flex flex-row  ' : 'nav-link d-flex flex-row '} to="/devis/parametre">
                        <Trans><span className="submenu-title">Personnaliser</span></Trans>
                      </Link>
                    </li>
                  </ul>
                </Collapse>
              </li>
              <li className={this.isPathActive('/facture/list') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to={{ pathname: `/facture/list`, state: { id: Helpers.getUser() == null ? null : Helpers.getUser().datauser.id } }}>
                  <i className="mdi mdi-receipt menu-icon" style={{ color: 'white' }}></i>
                  <span className="menu-title"><Trans>Facture</Trans></span>
                </Link>
              </li>
            </>}
        </>
        break;
      case "admin":
        return <>
          <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-view-dashboard menu-icon" style={{ color: 'white' }}></i>
              <span className="menu-title"><Trans>Tableau de bord</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/gestion-categorie') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/gestion-categorie">
              <i className="mdi mdi-crosshairs-gps menu-icon" style={{ color: 'white' }}></i>
              <span className="menu-title"><Trans>Gestion catégorie</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/gestion-user') ? 'nav-item active ' : 'nav-item '} style={{ borderRadius: '10px' }}>
            <div className={this.state.GestionUserMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('GestionUserMenuOpen')} data-toggle="collapse" >
              <i className="mdi mdi-account-multiple menu-icon" style={{ color: 'white' }}></i>
              <span className="menu-title"><Trans>Gestion Utilisateur</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.GestionUserMenuOpen} className="align-center arrondie-bas">
              <ul className="nav flex-column sub-menu ">
                <li className="nav-item">
                  <Link className={this.isPathActive('/gestion-user/list') ? 'nav-link active d-flex flex-row ' : 'nav-link d-flex flex-row '} to="/gestion-user/list">
                    <Trans><span className="submenu-title">Liste Client</span></Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={this.isPathActive('/gestion-user/pro-list') ? 'nav-link active d-flex flex-row ' : 'nav-link d-flex flex-row '} to="/gestion-user/pro-list">
                    <Trans><span className="submenu-title">Liste Professionnel</span></Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={this.isPathActive('/gestion-user/new') ? 'nav-link active d-flex flex-row  ' : 'nav-link d-flex flex-row '} to="/gestion-user/new">
                    <Trans><span className="submenu-title">Ajout</span></Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          <li className={this.isPathActive('/list-offre') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/list-offre">
              <i className="mdi mdi-view-list menu-icon" style={{ color: 'white' }}></i>
              <span className="menu-title"><Trans>Appel d'offre</Trans></span>
              <i className=""></i>
            </Link>
          </li>
          <li className={this.isPathActive('/gestion-produit') ? 'nav-item active ' : 'nav-item '} style={{ borderRadius: '10px' }}>
            <div className={this.state.GestionProduitMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('GestionProduitMenuOpen')} data-toggle="collapse" >
              <i className="mdi mdi-view-stream menu-icon" style={{ color: 'white' }}></i>
              <span className="menu-title"><Trans>Gestion Produit</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.GestionProduitMenuOpen} className="align-center arrondie-bas">
              <ul className="nav flex-column sub-menu ">
                <li className="nav-item">
                  <Link className={this.isPathActive('/gestion-produit/list') ? 'nav-link active d-flex flex-row ' : 'nav-link d-flex flex-row '} to="/gestion-produit/list">
                    <Trans><span className="submenu-title">Liste</span></Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={this.isPathActive('/gestion-produit/ajout') ? 'nav-link active d-flex flex-row  ' : 'nav-link d-flex flex-row '} to="/gestion-produit/ajout">
                    <Trans><span className="submenu-title">Ajout</span></Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          <li className={this.isPathActive('/devis') ? 'nav-item active ' : 'nav-item '} style={{ borderRadius: '10px' }}>
            <div className={this.state.GestionDevisMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('GestionDevisMenuOpen')} data-toggle="collapse" >
              <i className="mdi mdi-wallet menu-icon" style={{ color: 'white' }}></i>
              <span className="menu-title"><Trans>Gestion Devis</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.GestionDevisMenuOpen} className="align-center arrondie-bas">
              <ul className="nav flex-column sub-menu ">
                <li className="nav-item">
                  <Link className={this.isPathActive('/devis/list') ? 'nav-link active d-flex flex-row ' : 'nav-link d-flex flex-row '} to="/devis/list">
                    <Trans><span className="submenu-title">Liste</span></Trans>
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className={this.isPathActive('/devis/architect-validation-list') ? 'nav-link active d-flex flex-row ' : 'nav-link d-flex flex-row '} to="/devis/architect-validation-list">
                    <Trans><span className="submenu-title">Architecte validation</span></Trans>
                  </Link>
                </li> */}
                {/* <li className="nav-item">
                  <Link className={this.isPathActive('/devis/new') ? 'nav-link active d-flex flex-row  ' : 'nav-link d-flex flex-row '} to="/devis/new">
                    <Trans><span className="submenu-title">Création</span></Trans>
                  </Link>
                </li> */}
              </ul>
            </Collapse>
          </li>
          <li className={this.isPathActive('/facture/list') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to={{ pathname: `/facture/list`, state: { id: Helpers.getUser() == null ? null : Helpers.getUser().datauser.id } }}>
              <i className="mdi mdi-receipt menu-icon" style={{ color: 'white' }}></i>
              <span className="menu-title"><Trans>Facture</Trans></span>
            </Link>
          </li>
        </>
        break;
      case "client":
        return <>
          <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-view-dashboard menu-icon" style={{ color: 'white' }}></i>
              <span className="menu-title"><Trans>Tableau de bord</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/new-project') ? 'nav-item active' : 'nav-item'} onClick={() => this._onCreateNewProject()}>
            <Link className="nav-link" to="">
              <i className="mdi mdi-folder-plus menu-icon" style={{ color: 'white' }}></i>
              <span className="menu-title"><Trans>Nouveau projet</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/devis/list/') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to={{ pathname: `/devis/list/`, state: { id: Helpers.getUser() == null ? null : Helpers.getUser().datauser.id } }}>
              <i className="mdi mdi-folder-plus menu-icon" style={{ color: 'white' }}></i>
              <span className="menu-title"><Trans>Mes devis</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/facture/list') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to={{ pathname: `/facture/list`, state: { id: Helpers.getUser() == null ? null : Helpers.getUser().datauser.id } }}>
              <i className="mdi mdi-receipt menu-icon" style={{ color: 'white' }}></i>
              <span className="menu-title"><Trans>Facture</Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/payment/list') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to={{ pathname: `/payment/list`, state: { id: Helpers.getUser() == null ? null : Helpers.getUser().datauser.id } }}>
              <i className="mdi mdi-receipt menu-icon" style={{ color: 'white' }}></i>
              <span className="menu-title"><Trans>Paiement</Trans></span>
            </Link>
          </li>
        </>
        break;
      default:
        return <>
          <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-view-dashboard menu-icon" style={{ color: 'white' }}></i>
              <span className="menu-title"><Trans>Tableau de bord</Trans></span>
            </Link>
          </li>
        </>
        break;
    }
  }
  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar" style={{ boxShadow: ' 9px 0px 1px -6px #80808052' }}>
        <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
          <a className="sidebar-brand brand-logo" href="#"><img src={Icons.icLogoWhite} alt="logo" /></a>
          <a className="sidebar-brand brand-logo-mini pt-3" href="#"><img src={Icons.icLogoWhite} alt="logo" /></a>
        </div>
        <ul className="nav">
          {/* <li className="nav-item nav-profile not-navigation-link">
            <div className="nav-link">
              <Dropdown>
                <Dropdown.Toggle className="nav-link user-switch-dropdown-toggler p-0 toggle-arrow-hide bg-transparent border-0 w-100">
                  <div className="d-flex justify-content-between align-items-start">
                  <li >
                  <Link className="" to="/dashboard">
                    <i className="mdi mdi-view-list menu-icon"></i>
                    <span className="text-black"><Trans>Tableau de bord</Trans></span>
                  </Link>
                  </li>
                     <div className="profile-image">
                      <img className="img-xs rounded-circle" src={require("../../assets/images/faces/face8.jpg")} alt="profile" />
                      <div className="dot-indicator bg-success"></div>
                    </div>
                    <div className="text-wrapper">
                      <p className="profile-name">Allen Moreno</p>
                      <p className="designation">Premium user</p>
                    </div> 
                  </div>
                </Dropdown.Toggle>
              </Dropdown>
            </div>
          </li> */}
          <div className="m-2">
            {this._viewMenuPermission()}

            <li className={this.isPathActive('/logout') ? 'nav-item active' : 'nav-item'} onClick={() => this._onDeconnexion()}>
              <Link className="nav-link" to="/logout">
                <i className="mdi mdi-power menu-icon" style={{ color: 'white' }}></i>
                <span className="menu-title"><Trans>Deconnexion</Trans></span>
                <i className=""></i>
              </Link>
            </li>
          </div>

          {/* <li className={this.isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.GestionUserMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('GestionUserMenuOpen')} data-toggle="collapse">
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
              <span className="menu-title"><Trans>Basic UI Elements</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.GestionUserMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/basic-ui/buttons') ? 'nav-link active' : 'nav-link'} to="/basic-ui/buttons"><Trans>Buttons</Trans></Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/basic-ui/dropdowns') ? 'nav-link active' : 'nav-link'} to="/basic-ui/dropdowns"><Trans>Dropdowns</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={this.isPathActive('/form-elements') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.formElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('formElementsMenuOpen')} data-toggle="collapse">
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
              <span className="menu-title"><Trans>Form Elements</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.formElementsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/form-elements/basic-elements') ? 'nav-link active' : 'nav-link'} to="/form-elements/basic-elements"><Trans>Basic Elements</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={this.isPathActive('/tables') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.tablesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('tablesMenuOpen')} data-toggle="collapse">
              <i className="mdi mdi-table-large menu-icon"></i>
              <span className="menu-title"><Trans>Tables</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.tablesMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/tables/basic-table') ? 'nav-link active' : 'nav-link'} to="/tables/basic-table"><Trans>Basic Table</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={this.isPathActive('/icons') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.iconsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('iconsMenuOpen')} data-toggle="collapse">
              <i className="mdi mdi-account-box-outline menu-icon"></i>
              <span className="menu-title"><Trans>Icons</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.iconsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/icons/mdi') ? 'nav-link active' : 'nav-link'} to="/icons/mdi">Material</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={this.isPathActive('/charts') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.chartsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('chartsMenuOpen')} data-toggle="collapse">
              <i className="mdi mdi-chart-line menu-icon"></i>
              <span className="menu-title"><Trans>Charts</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.chartsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/charts/chart-js') ? 'nav-link active' : 'nav-link'} to="/charts/chart-js">Chart Js</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={this.isPathActive('/user-pages') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.userPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('userPagesMenuOpen')} data-toggle="collapse">
              <i className="mdi mdi-lock-outline menu-icon"></i>
              <span className="menu-title"><Trans>User Pages</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.userPagesMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/user-pages/login-1') ? 'nav-link active' : 'nav-link'} to="/user-pages/login-1"><Trans>Login</Trans></Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/user-pages/register-1') ? 'nav-link active' : 'nav-link'} to="/user-pages/register-1"><Trans>Register</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={this.isPathActive('/error-pages') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.errorPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('errorPagesMenuOpen')} data-toggle="collapse">
              <i className="mdi mdi-information-outline menu-icon"></i>
              <span className="menu-title"><Trans>Error Pages</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.errorPagesMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/error-pages/error-404') ? 'nav-link active' : 'nav-link'} to="/error-pages/error-404">404</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/error-pages/error-500') ? 'nav-link active' : 'nav-link'} to="/error-pages/error-500">500</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="http://www.bootstrapdash.com/demo/star-admin-free/react/documentation/documentation.html" rel="noopener noreferrer" target="_blank">
              <i className="mdi mdi-file-outline menu-icon"></i>
              <span className="menu-title"><Trans>Documentation</Trans></span>
            </a>
          </li> */}
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    // let type_user = Helpers.getUser().datauser.user_type
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(Sidebar);
const initialeState =
{
    data: [],
    bien:0
}
const Bien = (state = initialeState, action) => {
    switch (action.type) {
        case "SET_DATA_BIEN": {
            const nextState = {
                ...state,
                bien: action.value
            }
            return nextState
        }
        default:
            return state
    }
}
export default Bien

import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {Helpers} from '../config/Helpers'

import Spinner from '../app/shared/Spinner';

const Step = lazy(() => import('../containers/stepOne/Step'));
const Professionnel = lazy(() => import('../containers/professionnel/Professionnel'));
const Particulier = lazy(() => import('../containers/particulier/Particulier'));

const Login = lazy(() => import('../containers/login/Login'));
const ProjetClient = lazy(() => import('../containers/dashboard/projetClient/ProjetClient'));
const SingleProjet = lazy(() => import('../containers/dashboard/singleProjet/SingleProjet'));
const GestionCategorie = lazy(() => import('../containers/dashboard/gestionCategorie/GestionCategorie'));
const GestionBien = lazy(() => import('../containers/dashboard/gestionBien/GestionBien'));
const Documents = lazy(() => import('../containers/dashboard/document/Documents'));
const InformationLegale = lazy(() => import('../containers/entreprise/informationLegale/InformationLegale'));
const InformationGenerale = lazy(() => import('../containers/entreprise/informationGenerale/InformationGenerale'));
const Metier = lazy(() => import('../containers/entreprise/metier/Metier'));
const Reference = lazy(() => import('../containers/entreprise/reference/Reference'));
const Preference = lazy(() => import('../containers/entreprise/preference/Preference'));
const ZoneIntervention = lazy(() => import('../containers/entreprise/zoneIntervention/ZoneIntervention'));
const GestionUser = lazy(() => import('../containers/dashboard/gestionUser/GestionUser'));
const ListUser = lazy(() => import('../containers/dashboard/listUser/ListUser'));
const ListUserPro = lazy(() => import('../containers/dashboard/listUserPro/ListUserPro'));
const ListOffre = lazy(() => import('../containers/offres/ListOffre'));
const Profil = lazy(() => import('../containers/dashboard/profil/Profil'));
const GestionProduit = lazy(() => import('../containers/devis_facture/produit/gestionProduit/GestionProduit'));
const ListProduit = lazy(() => import('../containers/devis_facture/produit/listProduit/ListProduit'));
const CreateDevis = lazy(() => import('../containers/dashboard/createDevis/CreateDevis'));
const ListDevis = lazy(() => import('../containers/dashboard/listDevis/ListDevis'));
const ListDevisValidation = lazy(() => import('../containers/dashboard/listDevisValidation/ListDevisValidation'));
const ViewPdf = lazy(() => import('../containers/dashboard/viewPdf/viewPdf'));
const ManageLogo = lazy(() => import('../containers/dashboard/manageLogo/ManageLogo'));
const Payment = lazy(() => import('../containers/payment/Payment'));
const Payments = lazy(() => import('../containers/payment/Payments'));
const Reset = lazy(() => import('../containers/reset/Reset'));

const ListFacture = lazy(() => import('../containers/dashboard/listFacture/ListFacture'));
const ListPayment = lazy(() => import('../containers/dashboard/listPayment/ListPayment'));
const Payments_process = lazy(() => import('../containers/payment_processing/Payments_process'));

const Dashboard = lazy(() => import('./dashboard/Dashboard'));

const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));

const BasicElements = lazy(() => import('./form-elements/BasicElements'));

const BasicTable = lazy(() => import('./tables/BasicTable'));

const Mdi = lazy(() => import('./icons/Mdi'));

const ChartJs = lazy(() => import('./charts/ChartJs'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

// const Login = lazy(() => import('./user-pages/Login'));
// const Register1 = lazy(() => import('./user-pages/Register'));


class AppRoutes extends Component {
  render() {
    return (<>
      {Helpers.getUser() === null? 
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/" component={Particulier} />
          <Route exact path="/professionnel" component={Professionnel} />
          <Route path={"/payment/traitement"} component={Payments_process} />
          <Route exact path={"/reset-password"} component={Reset} />
          <Route exact path="/login" component={Login} />
          <Route path={"/payment/create"} component={Payments } />
          <Route path={"/viewpdf"} component={ViewPdf} />
          <Route path={"/payment/create"} component={Payments } />
          <Route path={"/viewpdf/:id"} component={ViewPdf} />
          <Route path={"/payment/traitement"} component={Payments_process} />
          <Redirect to="/login" />
        </Switch>
      </Suspense>:
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/" component={Particulier} />
          <Route exact path="/professionnel" component={Professionnel} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/dashboard" component={ProjetClient} />
          <Route path={"/single-projet/:id"} component={SingleProjet } />
          <Route path={"/single-projet"} component={SingleProjet} />
          <Route path={"/gestion-categorie"} component={GestionCategorie } />
          <Route path={"/gestion-bien/:id"} component={GestionBien } />
          <Route path={"/gestion-bien"} component={GestionBien } />
          <Route path={"/document"} component={Documents} />

          <Route path={"/entreprise/information-legale"} component={InformationLegale} />
          <Route path={"/entreprise/information-generale"} component={InformationGenerale} />
          <Route path={"/entreprise/metier"} component={Metier} />
          <Route path={"/entreprise/reference"} component={Reference} />
          <Route path={"/entreprise/preference"} component={Preference} />
          <Route path={"/entreprise/zone"} component={ZoneIntervention } />

          <Route path={"/gestion-user/new"} component={GestionUser} />
          <Route path={"/gestion-user/list"} component={ListUser} />
          <Route path={"/gestion-user/pro-list"} component={ListUserPro} />
          <Route path={"/list-offre"} component={ListOffre} />
          <Route path={"/profil"} component={Profil } />
          {/* <Route exact path={"/reset-password/:email/:token"} component={Reset} /> */}
          <Route exact path={"/reset-password"} component={Reset} />

          <Route path={"/gestion-produit/ajout"} component={GestionProduit} />
          <Route path={"/gestion-produit/ajout/:id"} component={GestionProduit} />
          <Route path={"/gestion-produit/list"} component={ListProduit} />
          <Route path={"/devis/new"} component={CreateDevis } />
          <Route path={"/devis/new/:id"} component={CreateDevis } />
          <Route path={"/devis/list"} component={ListDevis } />
          <Route path={"/devis/architect-validation-list"} component={ListDevisValidation } />
          <Route path={"/devis/list/:id"} component={ListDevis } />
          <Route path={"/viewpdf"} component={ViewPdf} />
          <Route path={"/viewpdf/:id"} component={ViewPdf} />
          <Route path={"/devis/parametre"} component={ManageLogo } />

          <Route path={"/facture/list"} component={ListFacture} />
          <Route path={"/payment/create"} component={Payments } />
          {/* <Route path={"/payment/create"} component={Payment } /> */}

          <Route path={"/payment/list"} component={ListPayment} />
          {/* <Route path={"/payment/traitement/:id"} component={Payments_process} /> */}
          <Route path={"/payment/traitement"} component={Payments_process} />

          {/* <Route path={"/payment/create/:id"} component={Payment } /> */}

          {/* <Route path={"/messages"} element={ListMessage } />
          <Route path={"/document"} element={Documents} />
           */}

          <Route exact path="/dashboard-admin" component={Dashboard} />
          <Route path="/form-Elements/basic-elements" component={BasicElements} />
          <Route path="/basic-ui/buttons" component={Buttons} />
          <Route path="/basic-ui/dropdowns" component={Dropdowns} />
          <Route path="/icons/mdi" component={Mdi} />

          {/* <Route path="/user-pages/login-1" component={Login} />
          <Route path="/user-pages/register-1" component={Register1} />

          <Route exact path="/dashboard" component={Dashboard} />

          <Route path="/basic-ui/buttons" component={Buttons} />
          <Route path="/basic-ui/dropdowns" component={Dropdowns} />

          <Route path="/form-Elements/basic-elements" component={BasicElements} />

          <Route path="/tables/basic-table" component={BasicTable} />

          <Route path="/icons/mdi" component={Mdi} />

          <Route path="/charts/chart-js" component={ChartJs} />

          <Route path="/error-pages/error-404" component={Error404} />
          <Route path="/error-pages/error-500" component={Error500} /> */}
          <Redirect to="/dashboard" />
        {/* {Helpers.getUser() === null?<Redirect to="/login" />:<Redirect to="/dashboard" />} */}
        </Switch>
      </Suspense>}
      </>
    );
  }
}

export default AppRoutes;
import { LoadingOutlined } from '@ant-design/icons'
import React, { Component } from 'react'
import './Spinner.scss';

export class Spinner extends Component {
  render() {
    return (
        // <div className="wrapper-spin">
        //   <div className="container-spin">
            <LoadingOutlined className='spin' />
        //   </div>
        // </div>
      // <div>
      //   <div className="spinner-wrapper">
      //     <div className="donut">
      //     </div>
      //   </div>
      // </div>
    )
  }
}

export default Spinner
